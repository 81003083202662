import { Toolbar, Typography } from "@mui/material"

export default function Header() {
  return (
    <Toolbar>
      <Typography component="h1" variant="h6">
        コード当てゲーム
      </Typography>
    </Toolbar>
  )
}
